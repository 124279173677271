// Imports
import Headroom from "headroom.js";

class Header {
    constructor() {
        this.headroom = null;
    }
    init() {
        const header = document.querySelector('.mn-hdr');

        if (header) {
            if (this.headroom) {
                this.destroy();
            }

            this.headroom = new Headroom(header, {
                offset: 100,
                tolerance: {
                    up: 8,
                    down: 0
                }
            });

            // Initialise
            this.headroom.init();
        }
    }
    pin() {
        this.headroom.unpin();
    }
    unpin() {
        this.headroom.unpin();
    }
    freeze() {
        this.headroom.freeze();
    }
    unfreeze() {
        this.headroom.unfreeze();
    }
    destroy() {
        this.headroom.destroy();
    }
}

export default new Header();



