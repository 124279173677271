    // Imports
import Alpine from 'alpinejs';
import gsap from "gsap";

// Project imports
import PageScrolling from '@js/modules/PageScrolling';

class Navigation {
    constructor() {
        this.init();
    }
    init() {
        this.nav = document.querySelector('.nav');
        this.navBtn = document.querySelector('.nav-btn');

        // Set up Alpine store to control nav toggle
        Alpine.store('nav', {
            isOpen: false,
            toggle() {
                if (this.isOpen) {
                    // Close nav
                    this.close();
                }
                else {
                    // Open nav
                    this.open();
                }
            },
            open() {
                // Open nav
                this.isOpen = true;
                console.log('Open nav');

                // Disable scroll for smaller screens only
                PageScrolling.lock(this.nav);

                // Hide header elements
                gsap.to(document.querySelector('.nav-btn'), {
                    alpha: 0,
                    duration: 0.1
                });
                gsap.to(document.querySelector('.hdr-page-title'), {
                    alpha: 0,
                    duration: 0.1
                });

                // Hide header
                const header = document.querySelector('.mn-hdr');
                if (header.classList.contains('headroom--pinned')){
                    header.classList.remove('headroom--pinned');
                }
            },
            close() {
                // Close nav
                this.isOpen = false;
                console.log('Close nav');

                // Enable scroll if viewport width is larger or equal than the breakpoint
                PageScrolling.unlock(this.nav);

                // Show header elements
                gsap.to(document.querySelector('.nav-btn'), {
                    delay: 0.3,
                    alpha: 1,
                    duration: 0.3
                });
                gsap.to(document.querySelector('.hdr-page-title'), {
                    delay: 0.3,
                    alpha: 1,
                    duration: 0.3
                });
            }
        });

        this.closeElements();

        this.setupCloseOnOutsideClick();

        // Escape key press
        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape' && Alpine.store('nav').open) {
                Alpine.store('nav').close();
            }
        });
    }
    closeElements() {
        // Close nav on element click
        const closeNavEls = document.querySelectorAll('[data-close-nav]');

        closeNavEls.forEach(el => {
            el.addEventListener('click', (e) => {
                Alpine.store('nav').close();
            });
        });
    }
    closeOnOutsideClick(event) {
        let navBtn = document.querySelector('.nav-btn');

        if (this.nav.contains(event.target) || navBtn.contains(event.target)) {
            return;
        }

        if (Alpine.store('nav').isOpen) {
            Alpine.store('nav').close();
        }
    }
    setupCloseOnOutsideClick() {
        // Close if clicked outside nav
        document.addEventListener('click', this.closeOnOutsideClick.bind(this));
    }
}

export default new Navigation();
