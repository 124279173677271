class ExternalLinks {
    constructor() {

    }
    build() {
        let links = document.querySelectorAll('a[href]');

        for (let i = 0; i < links.length; i++) {
            let link = links[i];

            if (link.hostname != location.hostname) {
                link.setAttribute('data-external', 'true');
            }
        }
    }
}

export default new ExternalLinks();
