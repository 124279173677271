class TransitionBgColor {
    constructor() {
        this.scrollStart = 0;
        this.scrollEnd = 0;
        this.startColor = '';
        this.endColor = '';
    }

    init() {
        const attrName = 'data-bg-transition';
        const transitionEl = document.querySelector('['+attrName+']');

        if (transitionEl) {
            const settings = transitionEl.getAttribute(attrName).split(',');

            const mobilePercentage = Number(settings[0] || 50) / 100;
            const desktopPercentage = Number(settings[1] || 50) / 100;
            this.startColor = settings[2];
            this.endColor = settings[3];

            // Get the footer element
            const content = document.body;
            const footerBounds = document.querySelector('.mn-ftr').getBoundingClientRect();
            const footerTop = Math.round(footerBounds.top);

            // Calculate the % content height position
            const contentHeight = content.offsetHeight;

            this.scrollStart = window.innerWidth < 768 ? Math.round(contentHeight * mobilePercentage) - window.innerHeight : Math.round(contentHeight * desktopPercentage) - window.innerHeight;
            this.scrollEnd = footerTop - window.innerHeight; // End of the scroll range

            this.updateBackgroundColor();

            // Update background color when scrolling
            window.addEventListener('scroll', this.handleScroll);
            window.addEventListener('resize', this.handleScroll);
        } else {
            this.cleanup();
        }
    }

    cleanup() {
        // Remove event listeners for scroll and resize
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleScroll);

        // Remove background color style from body element
        document.body.style.backgroundColor = null;
    }

    handleScroll = () => {
        this.updateBackgroundColor();
    };

    updateBackgroundColor() {
        const scrollPosition = window.scrollY;
        const progress = (scrollPosition - this.scrollStart) / (this.scrollEnd - this.scrollStart);

        if (progress > 0 && progress < 1) {
            const interpolatedColor = this.interpolateColor(this.startColor, this.endColor, progress);
            document.body.style.backgroundColor = interpolatedColor;
        } else if (progress <= 0) {
            document.body.style.backgroundColor = this.startColor;
        } else if (progress >= 1) {
            document.body.style.backgroundColor = this.endColor;
        }
    }

    interpolateColor(startColor, endColor, progress) {
        const startRGB = this.hexToRGB(startColor);
        const endRGB = this.hexToRGB(endColor);
        const interpolatedRGB = [];
        for (let i = 0; i < 3; i++) {
            interpolatedRGB.push(startRGB[i] + Math.round((endRGB[i] - startRGB[i]) * progress));
        }
        return `rgb(${interpolatedRGB.join(',')})`;
    }

    hexToRGB(hex) {
        hex = hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b);
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16)
        ] : null;
    }
}

export default new TransitionBgColor();
