// Project imports
import utils from '@js/utils/utils';

class VideoAsset {
    constructor() {
    }
    init(callback) {
        // Detect if motion is allowed and the device battery is charged enough
        if (!window.NURTURE.env.reducedMotion && utils.detectConnectionType() !== 'slow' && !utils.detectPerformanceBots()) {
            const videoEls = document.querySelectorAll('.asset-video');

            videoEls.forEach((video) => {
                this._show(video);

                /**
                 * Commented out due to it causing videos to play.
                 */
                // Test for support for readyState, else fallback to canplay event
                if (video.readyState >= video.HAVE_FUTURE_DATA) {
                    this._loadVideo(video);
                } else {
                    video.addEventListener('canplay', () => {
                        this._loadVideo(video);
                    }, false);
                }
            });

            // Function to start playing the video
            function playVideoIfInViewport() {
                videoEls.forEach((video) => {
                    if (utils.isElementInViewport(video)) {
                        if (!video.classList.contains('_started')) {
                            video.classList.add('_started');
                            video.play();
                        }
                    }
                });
            }

            // Create an Intersection Observer
            const observer = new IntersectionObserver(playVideoIfInViewport, {
                root: null,
                rootMargin: '0px',
                threshold: 0.1, // Trigger when 10% of the video is in the viewport
            });

            // Observe all video elements with the 'no-loop' class
            videoEls.forEach((video) => {
                observer.observe(video);
            });
        }
    }
    _show(video) {
        // Check if the video is not already visible
        if (video.style.display !== 'block') {
            // Show video
            video.style.display = 'block';
            video.style.opacity = '1';
        }
    }

    _loadVideo(video) {
        // Show & play video
        this._show(video);

        // Pause video, as it plays upon showing the element
        video.pause();

        // Play video if within viewport, as we forced paused it
        if (utils.isElementInViewport(video)) {
            video.classList.add('_started');
            video.play();
        }
    }
}

export default new VideoAsset();
