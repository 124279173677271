// Imports
import Alpine from "alpinejs";
import LazyLoad from 'vanilla-lazyload';
import reframe from 'reframe.js';

// Project imports
import ExternalLinks from "@js/modules/ExternalLinks";
import Carousels from "@js/modules/Carousels";
import Counter from '@js/modules/Counter';
import EndlessScroll from '@js/modules/EndlessScroll';
import Forms from '@js/modules/Forms';
import Header from '@js/modules/Header';
import Navigation from '@js/modules/Navigation';
import VideoAsset from '@js/modules/VideoAsset';
import TransitionBgColor from "@js/modules/TransitionBgColor";

class Page {
    constructor() {

    }
    init() {
        /**
         * Open external links in new tab
         */
        const extLinks = document.querySelectorAll('[data-external]');
        if (extLinks) {
            extLinks.forEach((item, i) => {
                item.addEventListener('click', (e) => {
                    let url = item.getAttribute('href');
                    window.open(url, '_blank').focus();
                });
            });
        }


        // External links
        ExternalLinks.build();

        // Navigation
        Navigation.closeElements();
        Navigation.setupCloseOnOutsideClick();

        TransitionBgColor.init();

        /**
         * Line animate links
         */
        const lineAnimateLinks = document.querySelectorAll('.line-animate-link');
        lineAnimateLinks.forEach(link => {
            link.addEventListener('mouseenter', () => {
                link.classList.add('_hover');
            });

            link.addEventListener('mouseleave', () => {
                link.classList.remove('_hover');
            });
        });

        /**
         * Lazy load
         */
        let lazyLoadInstance = new LazyLoad({
            threshold: 400
        });

        /**
         * Reframe
         */
        reframe('.reframe');

        /**
         * Header
         */
        Header.init();

        /**
         * Video asset
         */
        VideoAsset.init();

        /**
         * Counters
         */
        Counter.init();

        /**
         * Forms
         */
        Forms.start();

        /**
         * Endless Scroll
         */
        EndlessScroll.init();

        /**
         * Carousels
         */
        Carousels.init();
    }
}

export default new Page();
