// Imports
import { CountUp } from 'countup.js';

// Project imports
import env from '@js/utils/env';

class Counter {
    constructor() {
    }
    init() {
        this.attribute = 'data-countup';

        const easingFn = (t, b, c, d) => {
            const ts = (t /= d) * t;
            const tc = ts * t;
            return b + c * (tc + -3 * ts + 3 * t);
        };

        const countupEls = document.querySelectorAll(`[${this.attribute}]`);

        countupEls.forEach((el) => {
            const [value, prefix = '', suffix = ''] = el.getAttribute(this.attribute).split(',');

            const options = {
                easingFn,
                decimalPlaces: this._hasDecimal(value) ? 1 : 0,
                duration: env.reducedMotion ? 0 : 2,
                enableScrollSpy: true,
                scrollSpyDelay: 0,
                scrollSpyOnce: true,
                prefix,
                suffix,
            };

            const countupEl = new CountUp(el, value, options);

            if (countupEl.error) {
                console.error(countupEl.error);
            }
            // If `enableScrollSpy` is disabled calling `countupEl.start()` would be required
            // Place this in an else statement if no errors.
        });
    }
    _hasDecimal(value) {
        // Convert the input to a number if it's a string
        const numericValue = typeof value === 'string' ? parseFloat(value) : value;

        return numericValue !== Math.round(numericValue);
    }
}

export default new Counter();
