class HeaderHeightCalc {
	constructor() {
        this.calulate();

        // We listen to the resize event
        window.addEventListener('resize', () => {
            this.calulate();
        });
	}
    calulate() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let hh = document.querySelector('.mn-hdr').offsetHeight;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--hh', `${hh}px`);
    }
}

export default new HeaderHeightCalc();
