const formAttr = 'data-form';

class Forms {
    constructor() {

    }
    start() {
        const formEls = document.querySelectorAll(`[${formAttr}]`);

        // Loop through all forms
        formEls.forEach(form => {
            /**
             * Add asterisk to required field placeholder
             */
            const requiredInputs = form.querySelectorAll('[required]');
            // Loop through all forms
            requiredInputs.forEach(field => {
                const placeholderContent = field.getAttribute('placeholder');

                if (placeholderContent) {
                    if (!placeholderContent.endsWith("*")) {
                        field.setAttribute('placeholder', placeholderContent + '*');
                    }
                }
            });

            this.update(form);

            if (form.getAttribute('data-form-submit-method') !== 'ajax') {
                form.addEventListener('onAfterFormieSubmit', (e) => {
                    e.preventDefault();

                    this.update(form);
                });
            }
        });
    }

    update(form) {
        const formHandle = form.getAttribute(formAttr);

        // Find the CSRF token hidden input, so we can replace it
        let csrfInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]');

        // Fetch the new token for the form and replace the CSRF input with our new one
        fetch('/actions/formie/forms/refresh-tokens?form='+formHandle)
            .then(result => { return result.json(); })
            .then(result => {
                // CSRF update
                if (csrfInput) {
                    csrfInput.outerHTML = result.csrf.input;
                }

                // Find the JavaScript captcha hidden input, so we can update it
                if (result.captchas && result.captchas.javascript) {
                    // JavaScript captcha
                    let jsCaptcha = result.captchas.javascript;

                    form.querySelector('input[name="' + jsCaptcha.sessionKey + '"]').value = jsCaptcha.value;
                }

                // Find the Duplicate captcha hidden input, so we can update it
                if (result.captchas && result.captchas.duplicate) {
                    // Duplicate captcha
                    let duplicateCaptcha = result.captchas.duplicate;

                    form.querySelector('input[name="' + duplicateCaptcha.sessionKey + '"]').value = duplicateCaptcha.value;
                }

                // Update the form's hash (if using Formie's themed JS)
                // Update the Unload Event
                if (form.form && form.form.formTheme) {
                    form.form.formTheme.updateFormHash();
                }
            });
    }
}

export default new Forms();
