// Imports
import gsap from 'gsap';

class ProgressLine {
	constructor() {
		this.line = document.querySelector('.progress-bar');
		this.hasElement = !!this.line;
		this.state = {
			value: 0,
		};

		this.hasElement && gsap.set(this.line, { width: 0, scaleY: 0 });
	}
	show() {
		if (this.hasElement) {
			this.tween = gsap.fromTo(
				this.state,
				{ value: 0 },
				{
					value: 1,
					ease: 'circ.inOut',
					onUpdate: () => {
						if (this.tween) {
							this.tween.timeScale(1 - this.state.value);

							gsap.set(this.line, {
								width: this.state.value * 100 + '%',
							});
						}
					},
				}
			);

			gsap.set(this.line, {
				alpha: 0,
				scaleY: 0,
				transformOrigin: '0 0',
			});
			gsap.to(this.line, { alpha: 1, scaleY: 1, duration: 0.35 });
		}
	}
	hide() {
		if (this.hasElement) {
			gsap.killTweensOf(this.state);
			gsap.to(this.line, {
				width: '100%',
				alpha: 0,
				overwrite: true,
				duration: 0.35,
			});
		}
	}
}

export default new ProgressLine();
