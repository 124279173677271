// Imports
import Flickity from 'flickity';
import imagesLoaded from 'imagesloaded';

// Define common carousel options
const commonOptions = {
    arrowShape: 'M9.5 40 8 38.6 29 20 8 1.4 9.5 0 32 20z',
    cellAlign: 'left',
    cellSelector: '.carousel-cell',
    pageDots: false,
    prevNextButtons: false,
    wrapAround: true,
};

class Carousels {
    constructor() {}

    init() {
        const carouselOptions= {
            'general': {
                ...commonOptions,
            },
        };

        const carouselEls = document.querySelectorAll('.carousel-wrapper');
        carouselEls.forEach((el) => {
            let carousel = el.querySelector('.carousel');

            let imgsLoad = imagesLoaded(carousel);

            let carouselOptionsSettings = 'general';

            function onAlways(instance) {
                let flkty = new Flickity(carousel, carouselOptions[carouselOptionsSettings]);

                // Elements
                let arrows = el.querySelector('.carousel-arrows');
                let cellButtonGroup = el.querySelector('.carousel-pagination');

                if (cellButtonGroup) {
                    let cellButtons = cellButtonGroup.querySelectorAll('.carousel-pagination-dot');

                    cellButtons[flkty.selectedIndex].classList.add('is-selected');

                    // update selected cellButtons
                    flkty.on('select', function() {
                        let  selectedIndex = flkty.selectedIndex;

                        cellButtons.forEach(function(button) {
                            button.classList.remove('is-selected');
                        });
                        cellButtons[selectedIndex].classList.add('is-selected');
                    });

                    // select cell on button click
                    cellButtonGroup.addEventListener('click', function (event) {
                        if (!event.target.classList.contains('carousel-pagination-dot')) {
                            return;
                        }

                        let index = Array.from(cellButtons).indexOf(event.target);
                        flkty.select(index);
                    });
                }

                if (arrows) {
                    // previous
                    el.querySelector('.carousel-arrow-prev').addEventListener('click', function () {
                        flkty.previous();
                    });

                    // next
                    el.querySelector('.carousel-arrow-next').addEventListener('click', function () {
                        flkty.next();
                    });
                }
            }

            imgsLoad.on('always', onAlways);
        });
    }
}

export default new Carousels();
